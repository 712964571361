import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import "../styles/qna.scss"
const Qna = ({ data }) => (
  <Layout
    seoMetaTags={data?.datoCmsSafetyandqnapage?.seoMetaTags}
    page="Turvallisuus ja Q&A"
  >
    <div className="qna">
      <div className="content">
        <h1>{data?.datoCmsSafetyandqnapage?.pagetitle}</h1>
        <div
          dangerouslySetInnerHTML={{
            __html:
              data.datoCmsSafetyandqnapage.bodyNode.childMarkdownRemark.html,
          }}
        />
      </div>
    </div>
  </Layout>
)
export const query = graphql`
  query safetyQuery {
    datoCmsSafetyandqnapage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      pagetitle
      bodyNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default Qna
